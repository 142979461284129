/* eslint-disable func-names */
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../../Actions/types';
import callApi from '../Effects/callApi';
import repositoryApi from '../../Api/repository';
import {
  setReportingDocuments,
  setReportingLoading,
} from '../../Actions/reporting';
import { exportDocumentsExcel } from '../repository/exportExcelSaga';
import { resourceCorrectionOfNscale } from 'cosmos-config/utils';
import complete from '../Effects/complete';

function* runReportingQuery(folderId, nqlQuery, aggregationQuery) {
  const apiFunction = aggregationQuery
    ? repositoryApi.aggregateSearchDocuments
    : repositoryApi.queryFolderSearch;

  try {
    yield put(setReportingLoading(true, 'Loading reporting data'));
    const { items } = yield callApi(apiFunction, folderId, nqlQuery);

    const projectService = yield getContext('projectService');
    const propertiesMap = yield call(projectService.getProperties);

    const documents = resourceCorrectionOfNscale(items, propertiesMap);

    return documents;
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setReportingLoading(false));
  }
}

export default function* reportingSaga(projectCode, folderId) {
  yield takeLatest(actions.reporting.REPORTING_RUN_QUERY, function* (action) {
    const { nqlQuery, aggregationQuery } = action.payload;
    const documents = yield call(
      runReportingQuery,
      folderId,
      nqlQuery,
      aggregationQuery
    );

    yield put(setReportingDocuments(documents));
    yield complete(action, documents);
  });

  yield takeLatest(
    actions.reporting.REPORTING_EXPORT_TO_XLS,
    function* (action) {
      const { nqlQuery, aggregationQuery } = action.payload;
      const documents = yield call(
        runReportingQuery,
        folderId,
        nqlQuery,
        aggregationQuery
      );

      const columnsToExport = [];

      // const columns = yield select(getReportingColumnProperties);
      // const columnsToExport = columns.map((c) => c.name);

      yield call(exportDocumentsExcel, documents, columnsToExport);

      yield complete(action, documents);
    }
  );
}
