import * as actions from './types';

/**
 * Document action generators.
 * @module actions/document
 * @category Actions
 */

/**
 * Action for setting document loading.
 * @function
 * @param {boolean} loading - status flag of document loading
 * @param {string} message - toast message
 */
export const setDocumentLoading = (loading, message) => ({
  type: actions.document.SET_DOCUMENT_LOADING,
  loading,
  message,
});

/**
 * Action for downloading document.
 * @function
 * @param {function} callback - action results callback
 */
export const downloadDocument = () => ({
  type: actions.document.DOWNLOAD_DOCUMENT,
});

/**
 * Action for downloading document version.
 * @function
 * @param {} versionIdentifier - identifier of the current version
 * @param {function} callback - action results callback
 */
export const downloadDocumentVersion = (
  versionIdentifier,
  callback = () => {}
) => ({
  type: actions.document.DOWNLOAD_DOCUMENT_VERSION,
  versionIdentifier,
  callback,
});

/**
 * Action to fetch rendition by version.
 * @function
 * @param {} identifier -
 * @param {number} idemIndex -
 * @param {function} callback - action results callback
 * @param {function} progressCallback - progress callback
 * @param {function} errorCallback - error callback
 */
export const fetchVersionContentItem = (
  identifier,
  virtualRendition = false,
  idemIndex = 0,
  callback = () => {},
  progressCallback = () => {},
  errorCallback = () => {}
) => ({
  type: actions.document.FETCH_VERSION_CONTENT_ITEM,
  identifier,
  virtualRendition,
  idemIndex,
  callback,
  progressCallback,
  errorCallback,
});

/**
 * Action to fetch document content item.
 * @function
 * @param {} identifier -
 * @param {number} idemIndex -
 * @param {function} callback - action results callback
 * @param {function} progressCallback - progress callback
 * @param {function} errorCallback - error callback
 */
export const fetchDocumentContentItem = (
  identifier,
  virtualRendition = false,
  idemIndex = 0,
  callback = () => {},
  progressCallback = () => {},
  errorCallback = () => {}
) => ({
  type: actions.document.FETCH_DOCUMENT_CONTENT_ITEM,
  identifier,
  virtualRendition,
  idemIndex,
  callback,
  progressCallback,
  errorCallback,
});

/**
 * Action for fetching document thumbnail.
 * @function
 * @param {} identifier -
 * @param {function} callback - action results callback
 * @param {function} errorCallback - error callback
 */
export const fetchDocumentThumbnail = (
  identifier,
  callback = () => {},
  errorCallback = () => {}
) => ({
  type: actions.document.FETCH_DOCUMENT_THUMBNAIL,
  identifier,
  callback,
  errorCallback,
});

/**
 * Action for fetching document comment thumbnails.
 * @function
 * @param {} identifier -
 * @param {function} callback - action results callback
 * @param {function} errorCallback - error callback
 */
export const fetchDocumentCommentThumbnails = (
  identifier,
  callback = () => {},
  errorCallback = () => {}
) => ({
  type: actions.document.FETCH_DOCUMENT_COMMENT_THUMBNAILS,
  payload: {
    identifier,
    callback,
    errorCallback,
  },
});

/**
 * Action for sharing documents.
 * @function
 * @param {} resourceShare -
 * @param {function} callback - action results callback
 */
export const shareDocument = (resourceShare, callback = () => {}) => ({
  type: actions.document.SHARE_DOCUMENT,
  resourceShare,
  callback,
});

/**
 * Action for setting document presets.
 * @function
 * @param {} presets - definition key of the process that is started
 */
export const setDocumentPresets = (presets) => ({
  type: actions.repository.SET_DOCUMENT_PRESETS,
  presets,
});

/**
 * Action to open document.
 * @function
 * @param {} documentIdentifier -
 * @param {boolean} includePresets -
 * @param {boolean} reference -
 * @param {boolean} force -
 */
export const openDocument = (
  documentIdentifier,
  includePresets = false,
  reference = false,
  force = false
) => ({
  type: actions.document.OPEN_DOCUMENT,
  documentIdentifier,
  includePresets,
  reference,
  force,
});

/**
 * Action to close document.
 * @function
 * @param {} documentId -
 */
export const closeDocument = (documentId) => ({
  type: actions.document.CLOSE_DOCUMENT,
  documentId,
});

/**
 * Action setting opened document id.
 * @function
 * @param {} documentId -
 */
export const setOpenedDocumentId = (documentId) => ({
  type: actions.document.SET_OPENED_DOCUMENT_ID,
  documentId,
});

/**
 * Action for setting document.
 * @function
 * @param {} document - definition key of the process that is started
 */
export const setDocument = (document) => ({
  type: actions.document.SET_DOCUMENT,
  document,
});

/**
 * Action for fetching document versions.
 * @function
 * @param {function} callback - action results callback
 */
export const fetchDocumentVersions = () => ({
  type: actions.document.FETCH_DOCUMENT_VERSIONS,
});

/**
 * Action for setting document versions.
 * @function
 * @param {} versions -
 */
export const setDocumentVersions = (versions) => ({
  type: actions.document.SET_DOCUMENT_VERSIONS,
  versions,
});

/**
 * Action for fetching document versions' content.
 * @function
 * @param {} versionIds -
 * @param {function} callback - action results callback
 */
export const fetchDocumentVersionsContent = (
  versionIds,
  callback = () => {}
) => ({
  type: actions.document.FETCH_DOCUMENT_VERSIONS_CONTENT,
  versionIds,
  callback,
});

/**
 * Action for setting versions content.
 * @function
 * @param {} versionsContent - content of the versions
 */
export const setDocumentVersionsContent = (versionsContent) => ({
  type: actions.document.SET_DOCUMENT_VERSIONS_CONTENT,
  versionsContent,
});

/**
 * Action for updating document file.
 * @function
 * @param {} files - files to update document
 * @param {function} callback - action results callback
 */
export const updateDocumentFile = () => ({
  type: actions.document.UPDATE_DOCUMENT_FILE,
});

export const addFilesContentUpdate = (
  files,
  options = { shouldCheckDuplicates: false }
) => ({
  type: actions.document.ADD_FILES_CONTENT_UPDATE,
  payload: {
    files,
    options,
  },
});

/**
 * Action to preload document.
 * @function
 * @param {} documentIdentifier -
 */
export const preloadDocument = (documentIdentifier) => ({
  type: actions.document.PRELOAD_DOCUMENT,
  documentIdentifier,
});

/**
 * Action for setting document preload.
 * @function
 * @param {} preload - status flag of preloading document
 */
export const setDocumentPreload = (preload) => ({
  type: actions.document.SET_DOCUMENT_PRELOAD,
  preload,
});

/**
 * Action to set download progress.
 * @function
 * @param {} progress - downloading progress
 */
export const setDownloadProgress = (downloadProgress) => ({
  type: actions.document.SET_DOWNLOAD_PROGRESS,
  payload: {
    downloadProgress,
  },
});

/**
 * Action to for reloading document.
 * @function
 */
export const reloadDocument = () => ({
  type: actions.document.RELOAD_DOCUMENT,
});

/**
 * Action to scope selected document.
 * @function
 * @param {} documentIdentifier -
 */
export const scopeSelectedDocument = (documentIdentifier) => ({
  type: actions.repository.SCOPE_SELECTED_DOCUMENT,
  documentIdentifier,
});

/**
 * Action to check out document.
 * @function
 * @param {function} callback - action results callback
 */
export const checkOutDocument = (callback = () => {}) => ({
  type: actions.document.CHECK_OUT_DOCUMENT,
  callback,
});

/**
 * Action to check in document.
 * @function
 * @param {} file -
 * @param {function} callback - action results callback
 */
export const checkInDocument = (file, callback = () => {}) => ({
  type: actions.document.CHECK_IN_DOCUMENT,
  file,
  callback,
});

/**
 * Action for requesting signature.
 * @function
 * @param {} signRequest -
 * @param {function} callback - action results callback
 */
export const requestSignature = (signRequest) => ({
  type: actions.document.REQUEST_SIGNATURE,
  signRequest,
});

/**
 * Action to start document signature request.
 * @function
 * @param {} signatureRequest -
 */
export const setDocumentSignatureRequest = (signatureRequest) => ({
  type: actions.document.SET_DOCUMENT_SIGNATURE_REQUEST,
  signatureRequest,
});

/**
 * Action for cancelling signature request.
 * @function
 * @param {function} callback - action results callback
 */
export const cancelSignatureRequest = (callback = () => {}) => ({
  type: actions.document.CANCEL_SIGNATURE_REQUEST,
  callback,
});

/**
 * Action for setting signature request loading status.
 * @function
 * @param {boolean} loading - status flag of request loading
 * @param {string} message -
 */
export const setSignatureRequestLoading = (loading, message) => ({
  type: actions.document.SET_SIGNATURE_REQUEST_LOADING,
  loading,
  message,
});
