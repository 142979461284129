import * as actions from './types';

/**
 * Upload action generators.
 * @module actions/upload
 * @category Actions
 */

/**
 * Action for setting upload status in progress.
 * @function
 * @param {boolean} inProgress - status flag of the progress
 */
export const setUploadInProgress = (inProgress) => ({
  type: actions.upload.SET_UPLOAD_IN_PROGRESS,
  inProgress,
});

/**
 * Action for setting upload progress
 * @function
 * @param {} progress -
 */
export const setUploadProgress = (progress) => ({
  type: actions.upload.SET_UPLOAD_PROGRESS,
  progress,
});

/**
 * Action for updating upload progress.
 * @function
 * @param {} uuid -
 * @param {} progress -
 */
export const updateUploadProgress = (uuid, progress) => ({
  type: actions.upload.UPDATE_UPLOAD_PROGRESS,
  progress,
  uuid,
});

/**
 * Action for setting upload files.
 * @function
 * @param {} files -
 */
export const setUploadFiles = (files = []) => ({
  type: actions.upload.SET_UPLOAD_FILES,
  files,
});

/**
 * Action for adding files to upload.
 * @function
 * @param {} files - files to be uploaded
 * @param {} options - options for the upload
 */
export const addFilesToUpload = (
  files,
  options = { instant: false, meta: null, shouldCheckDuplicates: false },
) => ({
  type: actions.upload.ADD_FILES_TO_UPLOAD,
  files,
  options,
});

/**
 * Action to update a files content
 * @param filesToUpdate - files that are going to be updated
 * @param callback - callback that contains the document ids of the updated documents
 * @returns {{filesToUpdate, callback: callback, type: string}}
 */
export const updateFileContent = (
  filesToUpdate,
  callback = (docIds) => {}
) => ({
  type: actions.upload.UPDATE_FILE_CONTENT,
  filesToUpdate,
  callback,
});

export const updateUploadStats = (id, data) => ({
  type: actions.upload.UPDATE_UPLOAD_STATS,
  payload: {
    id,
    data,
  },
});

export const updateDocumentContent = (fileMatches, callback = () => {}) => ({
  type: actions.upload.UPDATE_DOCUMENT_CONTENT,
  fileMatches,
  callback,
});

export const uploadFilesNew = (
  fileIdentifiers,
  callback = (newDocumentids) => {}
) => ({
  type: actions.upload.UPLOAD_FILES_NEW,
  fileIdentifiers,
  callback,
});

/**
 * Action for unzipping the current to be uploaded files
 */
export const unzipFilesToUpload = (
  options = { shouldCheckDuplicates: false },
) => ({
  type: actions.upload.UNZIP_FILES_TO_UPLOAD,
  options,
});

/**
 * Action for setting the current unzipping loading state
 * @function
 * @param isUnzipping
 */
export const setIsUnzipping = (isUnzipping) => ({
  type: actions.upload.SET_IS_UNZIPPING,
  isUnzipping,
});

/**
 * Action for removing files from upload.
 * @function
 * @param {object[]} filesToRemove - Array of file identifiers
 */
export const removeFilesFromUpload = (filesToRemove) => ({
  type: actions.upload.REMOVE_FILES_FROM_UPLOAD,
  filesToRemove,
});

/**
 * Action for clearing upload data.
 * @function
 */
export const clearUploadData = (soft = false) => ({
  type: actions.upload.CLEAR_UPLOAD_DATA,
  payload: {
    soft,
  },
});

/**
 * Action for canceling upload.
 * @function
 */
export const cancelUpload = () => ({
  type: actions.upload.UPLOAD_PROCESS_CANCEL,
});

/**
 * Action for uploading files.
 * @function
 * @param {boolean} uploadWizard -
 */
export const uploadFiles = (uploadWizard = true) => ({
  type: actions.upload.UPLOAD_FILES,
  uploadWizard,
});

/**
 * Action to finish upload process.
 * @function
 */
export const finishUploadProcess = () => ({
  type: actions.upload.UPLOAD_PROCESS_SUCCESS,
});

/**
 * Action for setting loading status of uploading.
 * @function
 * @param {boolean} loading - loading status flag of uploading
 * @param {string} message - message text
 */
export const setUploadLoading = (loading, message) => ({
  type: actions.upload.SET_UPLOAD_LOADING,
  loading,
  message,
});

/**
 * Action for setting loading status of upload check.
 * @function
 * @param {boolean} loading - loading status of upload check
 * @param {string} message - message text
 */
export const setUploadCheckLoading = (
  loading,
  { message = '', loadingStats = {} } = {}
) => ({
  type: actions.upload.SET_UPLOAD_CHECK_LOADING,
  loading,
  message,
  loadingStats,
});

/**
 * Action for adding existing documents.
 * @function
 * @param {} documents -
 */
export const addExistingDocuments = (documents) => ({
  type: actions.upload.ADD_EXISTING_DOCUMENTS,
  documents,
});

/**
 * Action for setting current duplicates.
 * @function
 * @param duplicates - duplicates to be set
 */
export const setDuplicates = (duplicates) => ({
  type: actions.upload.SET_DUPLICATES,
  duplicates,
});

/**
 * Action for adding uploaded document id.
 * @function
 * @param {} documentId -
 */
export const addUploadedDocumentId = (documentId) => ({
  type: actions.upload.ADD_UPLOADED_DOCUMENT_ID,
  documentId,
});

/**
 * Action to start metadata assignment.
 * @function
 */
export const startMetadataAssignment = () => ({
  type: actions.upload.START_METADATA_ASSIGNMENT,
});

/**
 * Action to skip metadata assignment.
 * @function
 */
export const skipMetadataAssignment = () => ({
  type: actions.upload.SKIP_METADATA_ASSIGNMENT,
});

/**
 * Action to toggle uploading file confidential.
 * @function
 * @param {object} fileIdentifiers - name and path of the file
 * @param {} confidential -
 */
export const toggleUploadFileConfidential = (
  fileIdentifiers,
  confidential
) => ({
  type: actions.upload.TOGGLE_UPLOAD_FILE_CONFIDENTIAL,
  fileIdentifiers: {
    name: fileIdentifiers.name,
    path: fileIdentifiers.path,
  },
  confidential,
});

export const toggleUploadFileReference = (fileIdentifiers, reference) => ({
  type: actions.upload.TOGGLE_UPLOAD_FILE_REFERENCE,
  fileIdentifiers: {
    name: fileIdentifiers.name,
    path: fileIdentifiers.path,
  },
  reference,
});

/**
 * Action to update upload file order.
 * @function
 * @param {} index -
 * @param {number} amount -
 */
export const updateUploadFileOrder = (fileIdentifier, amount = 1) => ({
  type: actions.upload.UPDATE_UPLOAD_FILE_ORDER,
  payload: {
    fileIdentifier,
    amount,
  },
});

/**
 * Action to update upload file meta.
 * @function
 * @param {string} fileName - name of the file
 * @param {} meta -
 */
export const updateUploadFileMeta = (fileName, meta) => ({
  type: actions.upload.UPDATE_UPLOAD_FILE_META,
  fileName,
  meta,
});

/**
 * Action for adding placeholder to upload.
 * @function
 * @param {string} fileName - name of the file
 * @param {} meta -
 * @param {boolean} instant -
 */
export const addPlaceholderToUpload = (fileName, meta, instant = false) => ({
  type: actions.upload.ADD_PLACEHOLDER_TO_UPLOAD,
  fileName,
  meta,
  instant,
});

/**
 * Action for creating document stub.
 * @function
 * @param {} document -
 * @param {boolean} uploadWizard -
 */
export const createDocumentStub = (document, uploadWizard = true) => ({
  type: actions.upload.ADD_PLACEHOLDER_TO_UPLOAD,
  fileName: document.displayname,
  meta: document,
  instant: true,
  uploadWizard,
});

/**
 * Action for updating upload options.
 * @function
 * @param {string} name - name of the option
 * @param {} value - valuee of the option
 */
export const updateUploadOptions = (name, value) => ({
  type: actions.upload.UPDATE_UPLOAD_OPTIONS,
  name,
  value,
});

/**
 * Action for updating a files hash.
 * @param fileIdentifier
 * @param hash
 * @returns {{payload: {fileIdentifier, hash}, type: string}}
 */
export const updateFileHash = (fileIdentifier, hash) => ({
  type: actions.upload.UPDATE_FILE_HASH,
  payload: {
    fileIdentifier,
    hash,
  },
});
