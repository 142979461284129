/* eslint-disable func-names */
import {
  all,
  call,
  getContext,
  put,
  select,
  spawn,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import documentApi from '../../Api/document';
import {
  setDocumentLoading,
  setDocumentVersions,
  setDocumentVersionsContent,
} from '../../Actions/document';
import * as actions from '../../Actions/types';
import callApi from '../Effects/callApi';
import { notify } from '../../Actions/ui';
import resourceApi from '../../Api/resource';
import reportProgress from '../Effects/reportProgress';
import * as _ from 'lodash-es';
import complete from '../Effects/complete';
import repository from '../../Api/repository';

export function* fetchVersionRendition(
  versionidentifier,
  contentItemIndex = 0
) {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);
  const resourceId = `${docareaName}$6$NOTSET$104$${versionidentifier}`;

  const { data, contentType } = yield reportProgress(
    documentApi.getDocumentContentItem,
    resourceId,
    contentItemIndex
  );

  const blob = new Blob([data], { type: contentType });
  return URL.createObjectURL(blob);
}

function* fetchDocumentVersions(documentId) {
  try {
    const projectService = yield getContext('projectService');
    const properties = yield call(projectService.getProperties);

    yield put(setDocumentLoading(true, 'Loading document versions'));
    const updatableProps = properties
      .filter(
        (p) =>
          !p.system &&
          p.editable &&
          (p.updatable || p.disabled) &&
          p.query === p.name
      )
      .map((p) => p.name);

    const propsConditions = properties
      .filter((p) => p.conditional)
      .map((p) => p._conditionalProperty);

    const versions = yield callApi(
      resourceApi.getResourceVersions,
      documentId,
      _.concat(updatableProps, propsConditions)
    );

    yield put(setDocumentVersions(versions));
    return versions;
  } catch (err) {
    yield put(
      notify(
        `Failed to acquire document ${documentId} versions. Reason: ${err.message}.`
      )
    );
    console.error(err);
    return [];
  } finally {
    yield put(setDocumentLoading(false));
  }
}

export default function* versionsSaga(documentId) {
  yield takeLatest(
    actions.document.FETCH_DOCUMENT_VERSIONS,
    function* (action) {
      yield call(fetchDocumentVersions, documentId);

      yield complete(action);
    }
  );

  yield takeLatest(
    actions.document.FETCH_DOCUMENT_VERSIONS_CONTENT,
    function* ({ versionIds, callback }) {
      const blobUrls = yield all(
        versionIds.map((vid) => call(fetchVersionRendition, vid))
      );
      yield put(setDocumentVersionsContent(blobUrls));
      yield spawn(callback, blobUrls);
      return blobUrls;
    }
  );

  yield takeLeading(
    actions.document.DOWNLOAD_DOCUMENT_VERSION,
    function* ({ versionIdentifier, callback }) {
      const docareaService = yield getContext('docareaService');
      const docarea = yield call(docareaService.getDocareaName);
      const versionId = `${docarea}$${versionIdentifier}$NOTSET$2$NOTSET`;

      const { data, contentType, fileName } = yield reportProgress(
        repository.downloadDocuments,
        [versionId]
      );

      const blob = new File([data], fileName, { type: contentType });

      const document = yield select((state) => state.document.document);
      const { contentitemcount, displayname, filename } = document;

      const getFileName = () => {
        if (contentitemcount > 1) {
          return _.chain(displayname)
            .dropRightWhile((c) => c !== '.')
            .concat('zip')
            .join('');
        }

        return filename;
      };

      if (blob != null) {
        const contentUrl = URL.createObjectURL(blob);
        yield spawn(callback, contentUrl, getFileName());
      }
    }
  );
}
