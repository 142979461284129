import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';
import * as _ from 'lodash-es';
// import { getFixedValuesets } from './docarea';
import { simpleHash } from '../Utils';
import { getFixedAttributes } from './ui';

export const getSearchQuery = (state) => state.repository.searchQuery;
export const getFolderId = (state) => state.repository.folderId;
export const getFilterOptions = (state) => state.repository.filterOptions;
const getFilterFolder = (state) => state.filterFolder.filterFolder;
export const getFolder = (state) => state.repository.folder;
export const getFilter = (state) => state.repository.filter;
const getFiltersets = (state) => state.userdata.filtersets;
const getSelectedFiltersetId = (state) => state.userdata.selectedFiltersetId;
export const getDocuments = (state) => state.repository.documents;
export const getSelectedDocuments = (state) =>
  state.repository.selectedDocuments;
export const getHighlightedDocs = (state) => state.repository.highlightedDocs;
export const getSelectedFolder = (state) => state.repository.selectedFolder;
export const getValidationErrors = (state) => state.repository.validationErrors;
const isSubtreeQuery = (state) => state.repository.subtree;

export const getComputedFilter = createSelector(
  [getFilter, getFixedAttributes, getFilterFolder, isSubtreeQuery],
  (filter, fixedAttributes, filterFolder, subtree) => {
    // const getValue = (valueset) => {
    //   return valueset[0] != null ? [valueset[0].value] : [];
    // };
    // const orphanFilters = Object.entries(filterOptions)
    //   .filter(([name, valueset]) => valueset != null && valueset.length === 1)
    //   .reduce(
    //     (acc, [name, valueset]) => ({
    //       ...acc,
    //       [name]: getValue(valueset),
    //     }),
    //     {}
    //   );

    const resolveBaseFilter = _.cond([
      [() => subtree, _.constant({ ...filter, resourcetype: [2, 3] })],
      [_.stubTrue, _.constant(filter)],
    ]);

    const fixedAttrFilters = _.mapValues(
      _.keyBy(
        _.filter(
          fixedAttributes,
          (fo) =>
            !Object.keys(filter).includes(fo.property) &&
            (fo.folderType === filter.foldertype ||
              (_.isNil(fo.folderType) && _.isNil(filter.foldertype)))
        ),
        'property'
      ),
      'data'
    );

    if (filterFolder != null) {
      const filterFolderData = Object.entries(filterFolder).reduce(
        (acc, cur) => {
          const [key, value] = cur;
          acc[key] = [value];
          return acc;
        },
        {}
      );

      return {
        ...resolveBaseFilter(),
        ...fixedAttrFilters,
        ...filterFolderData,
      };
    }

    return {
      ...resolveBaseFilter(),
      // ...orphanFilters,
      ...fixedAttrFilters,
    };
  }
);

export const getFilterTouched = createSelector([getFilter], (filter) => {
  return Object.keys(filter).filter((key) => key !== 'foldertype').length > 0;
});

// export const hasMainStructure = createSelector(
//   getStructures,
//   (structures) => structures[structureType.MAIN] != null
// );

// export const hasFolderStructure = createSelector(
//   getStructures,
//   (structures) => structures[structureType.FOLDER] != null
// );

export const getSelectedFilterset = createSelector(
  [getFiltersets, getSelectedFiltersetId, getFilter],
  (filtersets, selectedFiltersetId, filter) => {
    const filterset = filtersets.find((fs) => fs.id === selectedFiltersetId);

    if (filterset != null) {
      if (
        simpleHash(JSON.stringify(filter)) ===
        simpleHash(JSON.stringify(filterset.filter))
      ) {
        return filterset;
      }
    }

    return null;
  }
);

export const getFilterHash = createSelector(getComputedFilter, (filter) => {
  const sortedFilter = Object.entries(filter)
    .sort((a, b) => String(a[0]).localeCompare(b[0]))
    .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});

  return simpleHash(JSON.stringify(sortedFilter));
});

export const getSelectedFolderInitialCreator = createSelector(
  getSelectedFolder,
  (folder) => {
    if (folder != null) {
      return folder.initialcreatorposition || [];
    }

    return [];
  }
);

export const getActiveFolder = createSelector(
  [getSelectedFolder, getFolder],
  (selectedFolder, folder) => {
    return selectedFolder || folder;
  }
);

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  _.isEqual
);

export const getSelectedDocumentId = createDeepEqualSelector(
  getSelectedDocuments,
  (selectedDocuments) => {
    if (selectedDocuments.length > 0) {
      return selectedDocuments[0]?.id;
    }

    return null;
  }
);

export const getOrderBy = createDeepEqualSelector(
  (state) => state.repository.orderBy,
  (orderBy) => {
    return _.chain({ resourcetype: 'asc' })
      .merge(orderBy)
      .mapKeys((v, key) => (key === 'fileextensionicon' ? 'contenttype' : key))
      .value();
  }
);

const createResourcesIdentifiersSelector = createSelectorCreator(
  defaultMemoize,
  (a, b) => {
    const getIdentifiers = (documents) => _.map(documents, 'identifier').sort();
    return _.isEqual(getIdentifiers(a), getIdentifiers(b));
  }
);

export const getSelectedDocumentsIdentifiers =
  createResourcesIdentifiersSelector(getSelectedDocuments, (documents) =>
    _.chain(documents).map('identifier').compact().sort().value()
  );

export const getClipboardIdentifiers = createSelector(
  (state) => state.repository.clipboard,
  (resources) => _.chain(resources).map('identifier').sort().value()
);
