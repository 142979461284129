/* eslint-disable func-names */
// eslint func-names: ["error", "always", { "generators": "as-needed" }]

import {
  put,
  call,
  takeLatest,
  select,
  takeEvery,
  fork,
  spawn,
  takeLeading,
  getContext,
} from 'redux-saga/effects';
import { channel } from 'redux-saga';
import * as _ from 'lodash-es';
import Fuse from 'fuse.js';
import { queryOperand, operation } from 'cosmos-config/nql';

import {
  clearRepositoryData,
  requestDocuments,
  selectDocuments,
  setDocuments,
  setFolder,
  setProxyFolderId,
  setRepositoryLoading,
  setRepositoryReady,
  setSelectedFolder,
  openFolder,
  clearClipboard,
  setOrderBy,
  resetOrderBy,
  createSubfolder,
} from '../../Actions/repository';
import * as actions from '../../Actions/types';
import { notify } from '../../Actions/ui';
import {
  buildDocument,
  prepareResourceForGateway,
  parseGatewayResources,
  parseGatewayResource,
} from '../../Utils/documentUtils';
import { fuzzyQuery, parseResourceId } from '../../Utils';
import { toQueryProperties } from '../../Utils/projectUtils';
import repository from '../../Api/repository';
import resourceApi from '../../Api/resource';
import repositoryApi from '../../Api/repository';
import { getDocuments, getSelectedFolder } from '../../Selectors/repository';
import callBatch from '../Effects/callBatch';
import complete from '../Effects/complete';
import callApi from '../Effects/callApi';
import uploadSaga from './uploadSaga';
import importSaga from './importSaga';
import documentSelectionSaga from './documentSelectionSaga';
import folderReportingSaga from './folderReportingSaga';
import repositorySearchSaga from './repositorySearchSaga';
import folderSaga from './folderSaga';
import versionImportSaga from './versionImportSaga';
import { generateGeneralPreset } from '.';

function* deleteFolder(folderId) {
  try {
    yield callApi(repository.deleteFolder, folderId);
  } catch (err) {
    yield put(
      notify(`Deletion of folder failed with reason: ${err.message}`, 'error')
    );
    console.error(err);
  }
}

function* fetchSearchSuggestions(folderId, { searchQuery, callback }) {
  if (searchQuery == null || searchQuery.trim().length <= 2) {
    return;
  }

  try {
    const searchOptions = {
      searchQuery: fuzzyQuery(searchQuery),
      paginator: { number: 0, size: 50 },
      subtree: true,
    };

    const { items } = yield call(
      repository.searchRepository,
      null,
      folderId,
      ['abstract', 'score'],
      searchOptions
    );

    const documents = items.map(buildDocument);

    const searchData = documents.map((d) => ({
      ...d,
      abstract: _.chain(d.abstract)
        .split(' ')
        .filter((a) => !_.isEmpty(a))
        .value(),
    }));

    const fuse = new Fuse(searchData, {
      keys: ['abstract'],
      includeMatches: true,
      minMatchCharLength: 3,
      includeScore: true,
      threshold: 0.4,
      ignoreLocation: true,
      useExtendedSearch: true,
    });

    const results = fuse.search(`^${searchQuery.trim().replace(' ', ' | ')}`);

    const options = _.chain(results)
      .reduce((acc, cur) => {
        const matches = cur.matches.map((m) => ({
          data: cur.item.abstract,
          index: m.refIndex,
          value: m.value,
        }));

        return acc.concat(matches);
      }, _.chain([]))
      .map(({ data, index }) => {
        const suggestedWord = data[index + 1];
        const sanitizedOption = data[index].replace(/\W/, '');

        if (suggestedWord != null && suggestedWord.match(/[A-Za-z]{3,}/)) {
          return `${sanitizedOption} ${suggestedWord}`;
        }

        return sanitizedOption;
      })
      .uniq()
      .sort()
      .map((option) => ({
        label: option,
        value: option,
      }))
      .value();

    yield spawn(callback, options);
  } catch (err) {
    console.error(err);
  }
}

function* fetchDeletedResources(folderId, action) {
  const { paginator, orderBy } = action.payload;

  const projectService = yield getContext('projectService');
  const properties = yield call(projectService.getProperties);
  const requestedProps = toQueryProperties(properties);

  try {
    const { items, count } = yield callApi(
      repository.getDeletedResources,
      folderId,
      requestedProps,
      paginator,
      orderBy
    );

    const documents = parseGatewayResources(items, properties);

    yield complete(action, { documents, count });
  } catch (err) {
    console.error(err);
  }
}

function* fetchAggregateLatest(projectCode, folderId, { payload }) {
  const {
    propertyName,
    groupingPropertyName,
    resourceType,
    callback,
    options,
  } = payload;

  const resourceTypeArray = resourceType != null ? [resourceType] : [1, 2];
  const searchOperation = operation(
    'in',
    queryOperand('resourcetype'),
    resourceTypeArray
  );

  try {
    const result = yield callApi(
      repository.fetchAggregateLatest,
      projectCode,
      folderId,
      propertyName,
      groupingPropertyName,
      searchOperation
    );

    const items = result
      .map((item) => {
        return _.chain(item.properties)
          .keyBy((property) => {
            const { name, value } = property;
            if (name === groupingPropertyName && typeof value === 'number') {
              return 'count';
            }

            return name;
          })
          .mapValues('value')
          .value();
      })
      .sort((a, b) => a[propertyName] - b[propertyName]);

    yield spawn(callback, items);
    return result;
  } catch (err) {
    yield put(notify(err.message, 'error'));
    console.error(err);
  }

  return [];
}

export function* fetchFolder(folderId, enableCache = false) {
  const projectService = yield getContext('projectService');
  const properties = yield call(projectService.getProperties);
  const requestedProps = toQueryProperties(properties);

  try {
    const result = yield callApi(
      repository.getFolder,
      folderId,
      requestedProps,
      enableCache
    );
    const folder = {
      ...parseGatewayResource(result, properties),
      id: folderId,
    };
    return folder;
  } catch (err) {
    yield put(
      notify(`Error while opening a project. Reason: ${err.message}`, 'error')
    );
  }

  return null;
}

function* fetchDocumentsByIdentifiers(projectCode, folderId, identifiers) {
  const projectService = yield getContext('projectService');
  const properties = yield call(projectService.getProperties);
  const requestedProps = toQueryProperties(properties);

  const items = yield callApi(
    repository.getDocumentsByIdentifiers,
    projectCode,
    folderId,
    identifiers,
    requestedProps,
    true
  );

  return parseGatewayResources(items, properties);
}

function* fetchFolders(folderId, { payload }) {
  const { folderType, options } = payload;
  const { callback } = options;

  const projectService = yield getContext('projectService');
  const propertiesMap = yield call(projectService.getProperties);

  try {
    const { items } = yield callApi(
      repository.getFolders,
      folderId,
      folderType,
      propertiesMap.map((p) => p.query)
    );

    const folders = parseGatewayResources(items, propertiesMap);

    if (callback != null) {
      yield spawn(callback, folders);
    }
  } catch (err) {
    console.error(err);
  }
}

function* createFolderDuplicate(folderId, { payload }) {
  const { folderId: folderToCopyId, folderType, options } = payload;
  const { callback } = options;

  try {
    yield put(setRepositoryLoading(true, 'Creating folder from a template.'));

    const projectService = yield getContext('projectService');
    const folderProperties = yield call(projectService.getFolderProperties);

    const templateCleanMask = _.chain(folderProperties)
      .filter((fp) => fp.folderType != null && fp.folderType !== folderType)
      .keyBy('name')
      .mapValues(() => null)
      .value();

    const folder = {
      ...templateCleanMask,
      valid: true,
      foldertype: folderType,
    };

    const properties = prepareResourceForGateway(folder, folderProperties);

    const id = yield callApi(
      repository.copyResource,
      folderToCopyId,
      folderId,
      properties
    );

    const { identifier } = parseResourceId(id);

    yield put(openFolder(identifier));

    if (callback != null) {
      yield spawn(callback);
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setRepositoryLoading(false, 'Creating folder from a template.'));
  }
}

function* pasteClipboard(folderId) {
  const clipboard = yield select((state) => state.repository.clipboard);
  const documents = _.chain(clipboard.documents)
    .filter((resource) => [2, 3].includes(resource.resourcetype))
    .uniqBy('identifier')
    .value();

  let presets = yield select((state) => state.document.presets);
  presets = _.chain(presets)
    .mapValues((v) => [v])
    .value();

  const projectService = yield getContext('projectService');
  const propertiesMap = yield call(projectService.getProperties);
  const updateProperties = prepareResourceForGateway(presets, propertiesMap);

  const hashes = documents.flatMap((doc) => doc.HashValue);

  const matches = yield callApi(
    repositoryApi.searchForHashes,
    folderId,
    hashes
  );

  const matchesDocs = parseGatewayResources(matches, propertiesMap);

  const existingDocuments = _.uniqBy(
    matchesDocs.map((doc) => ({
      name: doc.displayname,
      hash: doc.HashValue?.length ? doc.HashValue : doc.itemhash[0],
    })),
    'hash'
  );

  const sagas = documents.map((document) => {
    return call(function* () {
      if (
        existingDocuments.find(
          (doc) =>
            doc.hash === document.HashValue || doc.hash === document.itemhash[0]
        )
      ) {
        yield put(
          notify(`File: ${document.displayname} already exists`, 'error')
        );
      } else {
        try {
          if (document.parentresourceid !== folderId) {
            if (clipboard.mode === 'cut') {
              yield callApi(resourceApi.moveResource, document.id, folderId);
            }
            if (clipboard.mode === 'copy') {
              yield callApi(resourceApi.copyResource, document.id, folderId);
            }
          }

          yield callApi(
            resourceApi.updateResource,
            document.id,
            updateProperties
          );
        } catch (err) {
          console.error(err);
        }
      }
    });
  });

  yield callBatch(10, sagas);

  yield put(clearClipboard());
  yield put(requestDocuments());
}

function* selectFolderTemplate(proxyFolder) {
  const projectService = yield getContext('projectService');
  const properties = yield call(projectService.getFolderProperties);
  const editableProperties = properties
    .filter((p) => p.editable && p.updatable)
    .map((p) => p.name);

  const editableFolderMeta = _.pick(proxyFolder, editableProperties);

  const preset = yield call(generateGeneralPreset, properties);

  const defaultResource = {
    ...preset,
    ...editableFolderMeta,
    foldertype: proxyFolder.foldertype,
  };

  yield put(setSelectedFolder(defaultResource));

  if (properties.map((p) => p.type).includes('access-management')) {
    yield put(createSubfolder(proxyFolder.foldertype));
  }
}

export default function* projectFolderSaga({ payload }) {
  const { folderId, projectCode } = payload;

  yield put(clearRepositoryData());
  const folder = yield call(fetchFolder, folderId, true);

  if (folder == null) {
    return;
  }

  yield put(setFolder(folder));
  yield put(notify(`Opened folder project ${folder.displayname}.`, 'info'));

  // const folderPermissions = yield select(getFolderPermissions);
  // const folderCustomPermissions =
  //   folderPermissions[folder.objectclass]?.customPermissions;

  // if (
  //   Array.isArray(folderCustomPermissions) &&
  //   !folderCustomPermissions.includes('import') &&
  //   !folderCustomPermissions.includes('skipmetadata')
  // ) {
  //   yield put(fetchPoorDocumentsAction());
  // }

  yield takeEvery(actions.repository.FETCH_FOLDERS, fetchFolders, folderId);
  yield takeLeading(
    actions.repository.CREATE_FOLDER_DUPLICATE,
    createFolderDuplicate,
    folderId
  );

  const folderEventChannel = yield channel();

  yield takeLatest(
    actions.repository.SET_PROXY_FOLDER_ID,
    function* ({ folderId: proxyFolderId, contextOnly, force }) {
      const id = proxyFolderId || folderId;

      const selectedTeamspace = yield select(
        (state) => state.collaboration.selectedTeamspace
      );
      const isProxy = proxyFolderId != null && proxyFolderId !== folderId;
      const isTeamspace =
        isProxy &&
        selectedTeamspace != null &&
        selectedTeamspace.principalId != null;
      yield fork(uploadSaga, id, isTeamspace);
      yield fork(folderSaga, id);

      yield takeEvery(
        actions.repository.CANCEL_FOLDER_WIZARD,
        function* ({ callback }) {
          if (isProxy) {
            yield call(deleteFolder, id);
          }

          yield spawn(callback);
        }
      );

      if (isProxy) {
        yield takeLatest(
          actions.repository.DELETE_FOLDER,
          function* ({ callback }) {
            yield call(deleteFolder, id);
            yield spawn(callback);
          }
        );

        const resources = yield select(getDocuments);
        const selectedFolder = yield select(getSelectedFolder);

        let proxyFolder = resources.find((r) => r.id === id);

        if (proxyFolder == null && selectedFolder?.id === id) {
          proxyFolder = selectedFolder;
        }

        if (force || proxyFolder == null) {
          proxyFolder = yield call(fetchFolder, id, true);
        }

        if (contextOnly) {
          yield call(selectFolderTemplate, proxyFolder);
        } else {
          yield put(setSelectedFolder(proxyFolder));
        }
      }

      yield put(setDocuments([], 0));

      if (contextOnly) {
        return;
      }

      yield takeEvery(actions.repository.RESET_ORDERBY, function* () {
        const projectService = yield getContext('projectService');
        const columns = yield call(projectService.getTableColumns);
        const sortColumn = _.chain(columns)
          .filter('defaultSort')
          .map('name')
          .first()
          .value();

        const defaultOrderBy = {
          resourcetype: 'asc',
          lastmodifieddate: 'desc',
        };

        const orderBy =
          sortColumn != null && !isProxy
            ? { [sortColumn]: 'asc' }
            : defaultOrderBy;

        yield put(setOrderBy(orderBy));
      });

      yield fork(repositorySearchSaga, projectCode, id);
      yield takeLatest(
        actions.repository.SELECT_DOCUMENTS,
        documentSelectionSaga,
        projectCode,
        id
      );
      yield takeLatest(
        actions.repository.FETCH_SEARCH_SUGGESTIONS,
        fetchSearchSuggestions,
        id
      );
      yield takeLatest(
        actions.repository.FETCH_DELETED_DOCUMENTS,
        fetchDeletedResources,
        id
      );
      yield takeEvery(
        actions.repository.FETCH_AGGREGATE_LATEST,
        fetchAggregateLatest,
        projectCode,
        id
      );
      yield takeEvery(
        [
          actions.repository.FETCH_DOCUMENTS_BY_IDENTIFIERS,
          actions.repository.SELECT_DOCUMENTS_IDENTIFIERS,
        ],
        function* (action) {
          const { type, identifiers } = action;
          const documents = yield call(
            fetchDocumentsByIdentifiers,
            projectCode,
            id,
            identifiers
          );

          if (type === actions.repository.SELECT_DOCUMENTS_IDENTIFIERS) {
            yield put(selectDocuments(documents));
          }

          yield complete(action, documents);
        }
      );

      yield fork(importSaga, id);
      yield fork(folderReportingSaga, projectCode, id);
      yield fork(versionImportSaga, projectCode, id);

      yield takeLeading(actions.repository.PASTE_CLIPBOARD, pasteClipboard, id);

      // yield put(requestDocuments());
      yield put(resetOrderBy());

      folderEventChannel.put({
        type: 'FOLDER_READY',
        payload: {
          folderId: id,
        },
      });
    }
  );

  yield takeEvery(folderEventChannel, function* (action) {
    const repositoryReady = yield select((state) => state.repository.ready);

    if (
      action.type === 'FOLDER_READY' &&
      action.payload?.folderId === folderId &&
      !repositoryReady
    ) {
      yield put(setRepositoryReady(true));
    }
  });

  yield put(setProxyFolderId(folderId));
}
