/* eslint-disable func-names */
// eslint func-names: ["error", "always", { "generators": "as-needed" }]
import * as _ from 'lodash-es';
import {
  put,
  call,
  takeLatest,
  select,
  takeEvery,
  all,
  spawn,
  getContext,
} from 'redux-saga/effects';
import { setProxyFolderId } from '../../Actions/repository';
import {
  SET_FOLDER_ID,
  CLOSE_FOLDER,
  OPEN_FOLDER,
} from '../../Actions/types/repository';
import {
  buildDocument,
  prepareResourceForGateway,
} from '../../Utils/documentUtils';
import repository from '../../Api/repository';
import { clearUploadData } from '../../Actions/upload';
import callApi from '../Effects/callApi';
import { notify } from '../../Actions/ui';
import teamspaceFolder from '../../Constants/teamspaceFolder';
import { generateFolderId } from '../../Utils';
import resourceApi from '../../Api/resource';
import { getUserdata } from '../../Selectors/userdata';
import { FETCH_TEAMSPACE_FOLDERS } from '../../Actions/types/teamspaces';
import projectFolderSaga from './projectFolderSaga';
import {
  resourceCorrectionForGateway,
  resourceCorrectionOfGateway,
} from 'cosmos-config/utils';
import { setDocumentPresets } from '../../Actions/document';
import { getUploadPresets } from '../../Selectors/ui';
import { getFilterFolder } from '../../Selectors/filterFolder';
import { getComputedFilter } from '../../Selectors/repository';
import { getKeywords } from 'cosmos-core';
import { PresetStrategy } from 'cosmos-config/generator';

export function* generateGeneralPreset(propertiesMap) {
  const updatablePropertiesMap = _.chain(propertiesMap)
    .filter(
      (p) =>
        !p.disabled &&
        p.editable &&
        _.includes(p.presetable, PresetStrategy.FOLDER_INHERITANCE)
    )
    .mapKeys('name')
    .value();

  const filter = yield select(getComputedFilter);
  const filterPreset = _.chain(filter)
    .pickBy((value, key) => {
      return (
        Object.keys(updatablePropertiesMap).includes(key) &&
        _.filter(value, _.negate(_.isNull)).length > 0
      );
    })
    .mapValues((value, key) => {
      const property = updatablePropertiesMap[key];
      if (!property?.handleAsMulti && Array.isArray(value)) {
        return _.first(value);
      }

      return value;
    })
    .value();

  const userdata = yield select(getUserdata);
  const uploadPresets = yield select(getUploadPresets);
  const uploadPresetObject = _.chain(uploadPresets)
    .reduce((acc, cur) => ({ ...acc, [cur.property]: cur.data }), {})
    .value();
  const filterFolder = yield select(getFilterFolder);
  const preset = _.chain(updatablePropertiesMap)
    .omitBy((p) => _.isNull(p.defaultValue))
    .mapValues((p) => p.getDefaultValue(userdata))
    .omit('private')
    .merge(
      uploadPresetObject,
      filterFolder,
      resourceCorrectionOfGateway(filterPreset, propertiesMap)
    )
    .value();

  yield put(setDocumentPresets(preset));
  return preset;
}

export function* deleteDocuments(
  documentIds,
  physicalDelete,
  { callback } = {}
) {
  let deletedDocumentIds = yield all(
    documentIds.map(function* (id) {
      try {
        return yield callApi(resourceApi.deleteResource, id, physicalDelete);
      } catch (err) {
        yield put(
          notify(
            `Failed to delete document ${id}, reason: ${err.message}`,
            'error'
          )
        );
      }
      return null;
    })
  );

  deletedDocumentIds = deletedDocumentIds.filter((d) => d != null);

  if (callback != null) {
    yield spawn(callback, deletedDocumentIds);
  }

  return deletedDocumentIds;
}

export const resourceToProperties = (resource, propertiesMap, valuesetsMap) => {
  const keywords = getKeywords(resource, propertiesMap, valuesetsMap);
  const preparedResource = {
    ...resource,
    BasicKeywords: keywords,
    displayname: resource.displayname || resource.filename || null,
  };
  return prepareResourceForGateway(preparedResource, propertiesMap);
};

export const resourceToGateway = (resource, properties, valuesetsMap) => {
  const keywords = getKeywords(resource, properties, valuesetsMap);
  const preparedResource = {
    ...resource,
    BasicKeywords: keywords,
    displayname: resource.displayname || resource.filename || null,
  };

  const editableProperties = properties
    .filter((p) => (p.editable && p.updatable) || p.systemUpdatable)
    .map((p) => p.name);

  const correctedResource = resourceCorrectionForGateway(
    preparedResource,
    properties
  );

  const result = _.pick(correctedResource, editableProperties);

  return {
    ...result,
    id: resource.id,
    folderId: resource.folderId,
  };
};

export default function* repositorySaga() {
  yield takeLatest(SET_FOLDER_ID, projectFolderSaga);
  yield takeLatest([OPEN_FOLDER, CLOSE_FOLDER], function* (action) {
    if (action.type === CLOSE_FOLDER) {
      yield put(setProxyFolderId(null));
      yield put(clearUploadData());
    } else {
      const docareaService = yield getContext('docareaService');
      const docareaName = yield call(docareaService.getDocareaName);

      const { folderIdentifier, contextOnly, force } = action.payload;
      const folderId = generateFolderId(docareaName, folderIdentifier);

      yield put(setProxyFolderId(folderId, contextOnly, force));
    }
  });

  yield takeEvery(FETCH_TEAMSPACE_FOLDERS, function* ({ payload }) {
    const { folderId, options } = payload;
    const { callback } = options;

    // const folderPermissions = yield select(getFolderPermissions);

    const { items } = yield callApi(
      repository.getFolders,
      folderId,
      null,
      teamspaceFolder.map((p) => p.query)
    );

    const folders = items.map(buildDocument);
    yield spawn(
      callback,
      folders.filter((folder) => {
        // const permission = folderPermissions[folder.objectclass];
        // return permission != null && permission.readProperties;
        return true;
      })
    );
  });
}
