/* eslint-disable func-names */
import { put, call, getContext } from 'redux-saga/effects';
import * as _ from 'lodash-es';

import {
  addExistingDocuments,
  setDuplicates,
  setUploadCheckLoading,
} from '../../Actions/upload';
import { parseGatewayResources } from '../../Utils/documentUtils';
import repositoryApi from '../../Api/repository';
import { uploadErrorType } from '../../Constants/uploadErrorType';
import callApi from '../Effects/callApi';

export default function* duplicatesCheckerSaga(fileHashMap) {
  try {
    yield put(
      setUploadCheckLoading(
        true,
        'Checking for duplicated files in repository.'
      )
    );

    let duplicateContentMap = {};
    Object.entries(fileHashMap).forEach(([key, value]) => {
      if (duplicateContentMap[value.hash]) {
        duplicateContentMap[value.hash].push({
          fileIdentifier: key,
          name: value.name,
          path: value.path,
          hash: value.hash,
          type: uploadErrorType.FILE_CONTENT_DUPLICATE,
        });
      } else {
        duplicateContentMap[value.hash] = [
          {
            fileIdentifier: key,
            name: value.name,
            path: value.path,
            hash: value.hash,
            type: uploadErrorType.FILE_CONTENT_DUPLICATE,
          },
        ];
      }
    });
    duplicateContentMap = _.pickBy(
      duplicateContentMap,
      (matchingFiles) => matchingFiles.length !== 1
    );

    const projectService = yield getContext('projectService');
    const project = yield call(projectService.getOpenedProject);

    const { resourceId } = project;

    const matches = yield callApi(
      repositoryApi.searchForHashes,
      resourceId,
      Object.values(fileHashMap).map((o) => o.hash)
    );

    const propertiesMap = yield call(projectService.getProperties);

    const matchesDocs = parseGatewayResources(matches, propertiesMap);

    const existingDocs = _.flatMap(Object.values(fileHashMap), (hashObject) => {
      const filteredMatches = matchesDocs.filter(
        (md) =>
          md.HashValue === hashObject.hash ||
          md.itemhash?.includes(hashObject.hash)
      );
      return filteredMatches.map((md) => ({
        ...hashObject,
        identifier: md.identifier,
        matchedName: md.displayname,
        type: md.itemhash?.length > 1
          ? uploadErrorType.DOC_CONTENT_EXISTS_MULTI
          : uploadErrorType.DOC_CONTENT_EXISTS,
      }));
    });

    yield put(addExistingDocuments(existingDocs));
    yield put(setDuplicates(duplicateContentMap));

    return {
      existingDocs,
      duplicateContentMap,
    };
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setUploadCheckLoading(false));
  }

  return {};
}
