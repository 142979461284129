import Api from './conf/Api';
import CacheApi from './conf/CacheApi';
import GatewayApi from './conf/GatewayApi';
import {
  select,
  count,
  max,
  andOperation,
  orOperation,
  queryOperand,
  operation,
} from 'cosmos-config/nql';
import resource from './resource';
import * as _ from 'lodash-es';
import { generateSearchHash } from 'cosmos-core';

/**
 * @module api/repository
 * @category Api
 */

const GATEWAY_BASE_URL = import.meta.env.VITE_APP_GATEWAY_URL;

const api = Api.build();
const gatewayApi = GatewayApi.build();
const gatewayCacheApi = CacheApi.build(GATEWAY_BASE_URL);

const queryFolderSearch = (
  folderId,
  query,
  fulltextQuery = null,
  projectCode,
  cacheKey
) => {
  let data = { query, projectCode };

  if (fulltextQuery != null && fulltextQuery !== '') {
    data = {
      ...data,
      fulltextQuery,
    };
  }

  // const path = searchVersions ? 'searchversions' : 'search';

  const url = `/repository/folder/${folderId}/search`;

  let promise =
    cacheKey == null
      ? gatewayApi.put(url, data)
      : gatewayCacheApi.put(
          url,
          data,
          CacheApi.options({ cache: { ttl: 10 * 60 * 1000 } }, false, cacheKey)
        );

  return promise.then((response) => {
    const { items, count: itemsCount } = response.data;
    return Promise.resolve({ items, count: itemsCount });
  });
};

const searchRepository = async (
  projectCode,
  resourceId,
  properties = [],
  options = {},
  force = false
) => {
  const { searchQuery, paginator, orderBy, subtree, operation } =
    getSearchOptions(options);

  const query = select(properties).subtree(subtree).orderBy(orderBy);

  if (operation != null) {
    query.filter(operation);
  }

  if (paginator != null) {
    query.paging({ ...paginator, number: paginator.number + 1 });
  }

  const cacheKey = `SEARCH:${generateSearchHash(resourceId, options)}`;

  if (force) {
    await CacheApi.removeItem(cacheKey);
  }

  return queryFolderSearch(
    resourceId,
    query.toString(),
    searchQuery,
    projectCode,
    cacheKey
  );
};

const getSearchOptions = (options = {}) => ({
  filters: options.filters || [],
  searchQuery: options.searchQuery,
  paginator: options.paginator,
  orderBy: options.orderBy || {},
  subtree: !!options.subtree,
  substructure: !!options.substructure,
  isFulltext: !!options.isFulltext,
  searchProperties: options.searchProperties || [],
  operation: options.operation,
});

const getDeletedResources = (folderId, properties, paginator, orderBy) => {
  const filter = andOperation(
    orOperation(
      operation(
        'eq',
        queryOperand('initialcreator'),
        queryOperand('%currentUserPrincipalId')
      ),
      operation(
        'eq',
        queryOperand('creator'),
        queryOperand('%currentUserPrincipalId')
      )
    ),
    operation('in', queryOperand('resourcetype'), 1, 2, 3),
    operation('eq', queryOperand('hidden'), true)
  );

  const query = select(properties)
    // .orWhere({
    //   initialcreator: isCurrentUserPrincipalId(),
    //   creator: isCurrentUserPrincipalId(),
    // })
    // .where({
    //   resourcetype: isIn([1, 2, 3]),
    //   hidden: true,
    // })
    // .isNotDeleted()
    .filter(filter)
    .hidden()
    .subtree()
    .orderBy(orderBy);

  if (paginator != null) {
    query.paging({ ...paginator, number: paginator.number + 1 });
  }

  return queryFolderSearch(folderId, query.toString());
};

const clearCacheGetData = (response) => {
  return clearCache().then(() => {
    return Promise.resolve(response.data);
  });
};

const createStub = (folderId, document) => {
  return api
    .post(`/repository/folder/${folderId}/children/document`, document)
    .then(clearCacheGetData);
};

const createDocument = (
  folderId,
  files,
  properties = [],
  progressCallback = () => {}
) => {
  const data = new FormData();
  data.append('properties', JSON.stringify(properties));

  for (let i = 0; i < Array.from(files).length; i++) {
    const file = files[i];
    data.append(`files`, file, file.name);
  }

  return gatewayApi
    .post(`/repository/folder/${folderId}/children/document`, data, {
      responseType: 'json',
      onUploadProgress: (e) => {
        progressCallback(e.loaded);
      },
      // cancelToken: cancelTokenSource.token,
    })
    .then(clearCacheGetData);
};

const createRelatedDocument = (
  resourceId,
  relationType,
  files,
  properties,
  progressCallback = () => {}
) => {
  const data = new FormData();
  data.append('properties', JSON.stringify(properties));

  for (let i = 0; i < Array.from(files).length; i++) {
    const file = files[i];
    data.append(`files`, file, file.name);
  }

  return gatewayApi
    .post(
      `/repository/doc/${resourceId}/related-document/${relationType}`,
      data,
      {
        responseType: 'json',
        onUploadProgress: (e) => {
          progressCallback(e.loaded);
        },
        // cancelToken: cancelTokenSource.token,
      }
    )
    .then(clearCacheGetData);
};

const aggregateSearchDocuments = (folderId, query, projectCode) => {
  return gatewayApi
    .put(`/repository/folder/${folderId}/search/aggregate`, {
      projectCode,
      query,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const fetchAggregateLatest = (
  projectCode,
  folderId,
  property,
  groupingProperty,
  // resourceType,
  // filters = []
  operation
) => {
  const query = select([
    max(property),
    groupingProperty,
    count(groupingProperty),
  ])
    .filter(operation)
    // .where(filters.map(mapFiltersQuery))
    // .where({ resourcetype: resourceType || isIn([1, 2]) })
    .toString();

  return aggregateSearchDocuments(folderId, query, projectCode).then((data) => {
    return Promise.resolve(data.items);
  });
};

const getFolder = (folderId, properties = [], enableCache = false) => {
  const url = `/repository/res/${folderId}`;
  const options = {
    params: {
      properties: properties.join(','),
    },
  };

  if (!enableCache) {
    return gatewayApi.get(url, options).then((response) => {
      return Promise.resolve(response.data);
    });
  }

  return gatewayCacheApi
    .get(url, CacheApi.options(options, false, `FOLDER:${folderId}`))
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const getFolders = (folderId, folderType, properties = []) => {
  const query = select(properties)
    .filter(operation('eq', queryOperand('resourcetype'), 1))
    // .where({ resourcetype: isEqual(1) })
    .isNotDeleted();

  if (folderType != null) {
    query.where({ foldertype: folderType });
  }

  return queryFolderSearch(folderId, query.toString());
};

const searchForHashes = (
  folderId,
  hashes,
  properties = ['displayname', 'HashValue', 'itemhash', 'identifier']
) => {
  const filter = operation(
    'or',
    operation(
      'in',
      queryOperand('HashValue'),
      ...hashes.map((h) => h.toUpperCase())
    ),
    operation(
      'exists',
      operation(
        'in',
        queryOperand('itemhash'),
        ...hashes.map((h) => h.toUpperCase())
      )
    )
  );

  const query = select(properties).filter(filter).subtree().toString();

  return queryFolderSearch(folderId, query).then(({ items }) =>
    Promise.resolve(items)
  );
};

const createSubfolder = (folderId, properties) =>
  gatewayApi
    .post(`/repository/folder/${folderId}/children/folder`, properties)
    .then(clearCacheGetData);

const deleteFolder = (folderId) => {
  return api.delete(`/repository/folder/${folderId}`).then(clearCacheGetData);
};

/**
 * Create a resource reference into target folder. The document will be created within target folder of type 3.
 * @param {String} folderId Target folder receiving created reference.
 * @param {String} referenceResourceId Id of resource that will be rederenced within folder.
 * @param {String} objectclass Object class name of created reference.
 */
const createReference = (folderId, referenceResourceId, properties = []) => {
  return gatewayApi
    .post(
      `/repository/folder/${folderId}/children/link/${referenceResourceId}`,
      properties
    )
    .then(clearCacheGetData);
};

const getDocumentsByIdentifiers = (
  projectCode,
  folderId,
  identifiers = [],
  properties = [],
  subtree = false
) => {
  const filter = operation('in', queryOperand('identifier'), ...identifiers);

  const query = select(properties)
    // .where({ identifier: isIn(identifiers) })
    .filter(filter)
    .subtree(subtree)
    .toString();
  return queryFolderSearch(folderId, query, null, projectCode).then(
    ({ items }) => Promise.resolve(items)
  );
};

const getDocumentsByIds = (
  folderId,
  documentIds = [],
  properties = [],
  subtree = false
) => {
  const identifiers = documentIds
    .map((id) => String(id).split('$')[2])
    .map((identifier) => parseInt(identifier, 10));
  return getDocumentsByIdentifiers(
    null,
    folderId,
    identifiers,
    properties,
    subtree
  );
};

const updateFolder = (folderId, properties) => {
  return resource
    .updateResource(folderId, properties)
    .then(clearCacheGetData)
    .then(() => {
      CacheApi.removeItem(`FOLDER:${folderId}`);
      return Promise.resolve(folderId);
    });
};

const importResources = (folderId, resources = [], resourceType = 1) => {
  return gatewayApi
    .post(`/repository/folder/${folderId}/children/import`, {
      resources,
      resourceType,
    })
    .then(clearCacheGetData);
};

const copyResource = (resourceId, targetFolderId, properties = []) => {
  return gatewayApi
    .post(`/repository/res/${resourceId}/copy/${targetFolderId}`, properties)
    .then(clearCacheGetData);
};

const clearCache = () => {
  return CacheApi.clearCache(['SEARCH', 'FOLDER']);
};

const downloadDocuments = (resourceIds = [], progressCallback = () => {}) => {
  return gatewayApi
    .get(`/repository/download`, {
      responseType: 'blob',
      params: {
        resourceIds: resourceIds.join(),
      },
      onDownloadProgress: (e) => {
        if (e.lengthComputable) {
          const progress = parseFloat((e.loaded / e.total) * 100);
          progressCallback(_.round(progress, 2));
        }
      },
    })
    .then((response) => {
      console.log(response);

      const contentDisposition = response.headers['content-disposition'];
      var fileName = contentDisposition.match(/filename\s*=\s*"(.+)"/i)[1];

      console.log(response, fileName);
      return Promise.resolve({
        data: response.data,
        contentType: response.headers['content-type'],
        fileName,
      });
    });
};

const unlockResource = (resourceId) => {
  return api
    .put(`/repository/res/${resourceId}/command/unlock`)
    .then(clearCacheGetData);
};

export default {
  searchRepository,
  createStub,
  createDocument,
  createRelatedDocument,
  aggregateSearchDocuments,
  getFolders,
  getFolder,
  searchForHashes,
  createSubfolder,
  deleteFolder,
  createReference,
  getDocumentsByIdentifiers,
  getDocumentsByIds,
  getDeletedResources,
  updateFolder,
  queryFolderSearch,
  importResources,
  fetchAggregateLatest,
  copyResource,
  clearCache,
  downloadDocuments,
  unlockResource,
};
