export const SET_DOCUMENT_LOADING = 'resource/setLoading';
export const CLEAR_DOCUMENT_DATA = 'resource/clearData';
export const FETCH_VERSION_CONTENT_ITEM = 'resource/fetchVersionContentItem';
export const FETCH_DOCUMENT_CONTENT_ITEM = 'resource/fetchContentItem';
export const FETCH_DOCUMENT_COMMENT_THUMBNAILS =
  'document/fetchCommentThumbnails';
export const SET_DOCUMENT_PRELOAD = 'resource/setPreload';
export const FETCH_DOCUMENT_THUMBNAIL = 'resource/fetchThumbnail';
export const FETCH_DOCUMENT_PROPERTIES = 'FETCH_DOCUMENT_PROPERTIES';
export const FETCH_DOCUMENT_VERSIONS = 'FETCH_DOCUMENT_VERSIONS';
export const FETCH_DOCUMENT_VERSIONS_CONTENT =
  'FETCH_DOCUMENT_VERSIONS_CONTENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const SHARE_DOCUMENT = 'SHARE_DOCUMENT';
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const DOWNLOAD_DOCUMENT_VERSION = 'DOWNLOAD_DOCUMENT_VERSION';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const OPEN_DOCUMENT = 'OPEN_DOCUMENT';
export const RELOAD_DOCUMENT = 'RELOAD_DOCUMENT';
export const PRELOAD_DOCUMENT = 'PRELOAD_DOCUMENT';
export const CLOSE_DOCUMENT = 'CLOSE_DOCUMENT';
export const SET_OPENED_DOCUMENT_ID = 'SET_OPENED_DOCUMENT_ID';
export const SET_DOCUMENT_SIGNATURE_REQUEST = 'SET_DOCUMENT_SIGNATURE_REQUEST';
export const CANCEL_SIGNATURE_REQUEST = 'CANCEL_SIGNATURE_REQUEST';
export const SET_SIGNATURE_REQUEST_LOADING = 'SET_SIGNATURE_REQUEST_LOADING';
export const REPAIR_REUPLOAD_CONTENT = 'REPAIR_REUPLOAD_CONTENT';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_DOCUMENT_VERSIONS = 'SET_DOCUMENT_VERSIONS';
export const SET_DOCUMENT_VERSIONS_CONTENT = 'SET_DOCUMENT_VERSIONS_CONTENT';
export const SET_DOWNLOAD_PROGRESS = 'SET_DOWNLOAD_PROGRESS';
export const UPDATE_DOCUMENT_FILE = 'UPDATE_DOCUMENT_FILE';
export const ADD_FILES_CONTENT_UPDATE = 'document/addFilesContentUpdate';
export const CHECK_OUT_DOCUMENT = 'CHECK_OUT_DOCUMENT';
export const CHECK_IN_DOCUMENT = 'CHECK_IN_DOCUMENT';
export const REQUEST_SIGNATURE = 'REQUEST_SIGNATURE';
export const SET_LOADING_PROJECT = 'SET_LOADING_PROJECT';
