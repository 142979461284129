import { all, call } from 'redux-saga/effects';
import { getFileIdentifier, progressiveChecksum } from 'cosmos-core';

const defaultChunkSizeLimit = 1024 * 1024 * 1024; // 1GB;
const sizeLimit = import.meta.env.VITE_APP_CONTENT_HASH_CHUNK_SIZE_LIMIT;
const SIZE_LIMIT =
  sizeLimit && !isNaN(sizeLimit) ? +sizeLimit : defaultChunkSizeLimit;

export function* createContentHashSagas(files, progressCallback) {
  const chunks = _createChunks(files, SIZE_LIMIT);

  let fileContentHashGeneratedCount = 0;
  yield call(progressCallback, fileContentHashGeneratedCount);

  let hashResults = [];

  for (let chunk of chunks) {
    const chunkResults = yield all(
      chunk.map((file) =>
        call(function* () {
          const result = yield call(_contentHashSaga, file);
          ++fileContentHashGeneratedCount;
          if (progressCallback) {
            yield call(progressCallback, fileContentHashGeneratedCount);
          }
          return result;
        })
      )
    );

    hashResults.push(...chunkResults);
  }

  return hashResults;
}

function _createChunks(files, chunkSizeLimit) {
  const chunks = [];
  let currentChunk = [];
  let currentChunkSize = 0;

  for (let file of files) {
    if (file.size > chunkSizeLimit) {
      if (currentChunk.length) {
        chunks.push(currentChunk);
        currentChunk = [];
        currentChunkSize = 0;
      }
      chunks.push([file]);
    } else if (currentChunkSize + file.size > chunkSizeLimit) {
      chunks.push(currentChunk);
      currentChunk = [file];
      currentChunkSize = file.size;
    } else {
      currentChunk.push(file);
      currentChunkSize += file.size;
    }
  }

  if (currentChunk.length) {
    chunks.push(currentChunk);
  }

  return chunks;
}

function* _contentHashSaga(file) {
  const hash = yield call(progressiveChecksum, file);

  return {
    name: file.name,
    path: file.path || '',
    hash: hash.toUpperCase(),
    fileIdentifier: getFileIdentifier(file),
  };
}
