import {
  valuesetSource,
  property,
  systemProperty,
} from 'cosmos-config/generator';

export default [
  systemProperty('objectclass'),
  systemProperty('parentresourceid'),
  systemProperty('resourcetype'),
  systemProperty('refresourceid'),
  systemProperty('refresourcetype'),
  property('File Type', 'fileextension')
    .excludeFilter()
    .excludeForm()
    .excludePreset()
    .translate('common.file_type'),
  systemProperty('itemdisplayname').multiAttribute('itemdisplayname'),
  systemProperty('itemlength').multiAttribute('itemlength'),
  // systemProperty('itemcontenttype').multiAttribute('itemcontenttype'),
  systemProperty('contentitemcount'),
  systemProperty('lockowner'),
  systemProperty('deletestate'),
  systemProperty('Keywords', 'BasicKeywords', true).multiAttribute(
    'BasicKeywords'
  ),
  systemProperty('hidden').booleanAttribute('hidden'),
  systemProperty('creatorcommonname'),
  // systemProperty('initialcreatorposition'),
  systemProperty('creationdate'),
  systemProperty('version'),
  systemProperty('versionidentifier'),
  systemProperty('refresourceversionidentifier').resolveReference(
    'refresourceid',
    1,
    'versionidentifier'
  ),
  property('Identifier', 'identifier')
    .excludeForm()
    .excludeFilter()
    .excludePreset()
    .translate('common.identifier'),
  // TODO: can be removed after the new hash solution is stable
  systemProperty('Checksum', 'itemhash', true).multiAttribute('itemhash'),
  systemProperty('Content Hash', 'HashValue', true),
  systemProperty('ProjectSector'),
  systemProperty('DocumentNumber'),
  systemProperty('contentlength').numericAttribute('contentlength'),
  systemProperty('foldertype', 'foldertype', true),
  // systemProperty('private'),
  // systemProperty('parentobjectclass').resolveReference(
  //   'parentidentifier',
  //   1,
  //   'objectclass'
  // ),
  // systemProperty('Breadcrumb')
  //   .multiAttribute('breadcrumb')
  //   .resolveReference('pathidentifiers', 1, 'displayname'),
  systemProperty('pathidentifiers').multiAttribute('pathidentifiers'),
  systemProperty('filename', 'filename', true),
  systemProperty('lockownercommonname'),
  // systemProperty('signed').booleanAttribute('signed'),
  systemProperty('envelopeId'),
  // systemProperty('linkreferencetype'),
  systemProperty('Filetype', 'contenttype'),
  systemProperty('Linked Documents').multiAttribute('LinkedDocs'),
  // systemProperty('Linked Documents Names')
  //   .multiAttribute('LinkedDocsDisplayNames')
  //   .resolveReference('LinkedDocs', 1, 'displayname'),
  // property('eSign Status', 'signingstatus')
  //   .excludeForm()
  //   .excludeFilter()
  //   .excludePreset()
  //   .tableCell((value) => {
  //     switch (value) {
  //       case 'ACTIVE':
  //         return h(FontAwesomeIcon, {
  //           icon: faCircle,
  //           size: 'lg',
  //           className: 'text-warning',
  //         });
  //       case 'COMPLETE':
  //         return h(FontAwesomeIcon, {
  //           icon: faCircle,
  //           size: 'lg',
  //           className: 'text-success',
  //         });
  //       default:
  //         return null;
  //     }
  //   }),
  systemProperty('targeturl', 'targeturl', true),
  systemProperty('placeholder', 'placeholder', true),

  // systemProperty('signaturedate'),
  property('eSign Status', 'signaturestatus')
    .selectableAttribute(
      'signaturestatus',
      'global_signature_status',
      valuesetSource.VALUESET
    )
    .excludeForm()
    .excludeFilter()
    .excludePreset(),
  // systemProperty('parentdocumentid', 'parentdocumentid', true),
];
